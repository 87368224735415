import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import blog11 from "../assets/images/blogs/blog11.jfif";
import blogoverlay from "../assets/images/blogs/blogoverlay.png";
import blogoverlay1 from "../assets/images/blogs/blogoverlay1.png";
import blogoverlay2 from "../assets/images/blogs/blogoverlay2.png";
import blogoverlay4 from "../assets/images/blogs/blogoverlay4.png";
import { Link } from "react-router-dom";
import latestblog1 from "../assets/images/blogs/latestblogimg2.jpeg";
import blogee2 from "../assets/images/blogs/blogee2.jpg";
import blogee3 from "../assets/images/blogs/3.jpg";
import blog7 from "../assets/images/blogs/7.png";
import blog10 from "../assets/images/blogs/10.png";
import scale from "../assets/images/blogs/scale.png";
import virtual1 from "../assets/images/blogs/virtual1.png";
import tax7 from "../assets/images/blogs/tax7.png";
import { Helmet } from "react-helmet";
import newblogimg from "../assets/images/blogs/newblogimg2.png";
import { Button } from "@mui/material";
import sustain3 from "../assets/images/blogs/Sustainability3.png";
import envole2 from "../assets/images/blogs/bostonblog.png";
import envole from '../assets/images/blogs/envole.png'
import taxing1 from "../assets/images/blogs/taxing1.png";
import taxtime from '../assets/images/blogs/taxtime1.png'
import taxtime1 from '../assets/images/blogs/taxtime2.png'
import taxtime2 from '../assets/images/blogs/taxtime3.png'
import internaltax from '../assets/images/blogs/InternationalTaxEra1.png'
import internaltax1 from '../assets/images/blogs/InternationalTaxEra2.png'
import internaltax2 from '../assets/images/blogs/InternationalTaxEra3.png'
import fbar from '../assets/images/blogs/FBAR1.png'
import fbar1 from '../assets/images/blogs/FBAR2.png'
import fbar2 from '../assets/images/blogs/FBAR3.png'
import fbar3 from '../assets/images/blogs/FBAR4.png'
import BlogMobile from "./mobileblog";

const BlogTwo = () => {
  const [hovered, setHovered] = useState(null);
  const [showMore, setShowMore] = useState(false);

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };
  const BlogCard = ({ 
    to, 
    image, 
    date, 
    title, 
    index, 
    customStyle = {}, 
    overlayStyle = {},
    dateContainerStyle = {},
    titleStyle = {}
  }) => {
    return (
      <Link
        to={to}
        className="blog-card"
        onMouseEnter={() => setHovered(index)}
        onMouseLeave={() => setHovered(null)}
      >
        <div 
          className="blog-card-image" 
          style={{
            backgroundImage: `url(${image})`,
            ...customStyle
          }}
        >
          <div
            className={`blog-card-overlay ${hovered === index ? 'overlay-visible' : ''}`}
            style={{
              ...(hovered === index ? { backgroundColor: 'rgba(0, 112, 255, 0.61)' } : {}),
              ...overlayStyle
            }}
          ></div>
          <div 
            className="blog-card-date-container" 
            style={dateContainerStyle}
          >
            <div className="blog-card-date">{date}</div>
            <div className="blog-card-date-line"></div>
          </div>
          <div 
            className="blog-card-title" 
            style={titleStyle}
            dangerouslySetInnerHTML={{ __html: title }}
          />
        </div>
      </Link>
    );
  };

 
  return (
    <>
      <Helmet>
        <title>Boston Financial Advisory Group - Insights and Expertise</title>
        <meta 
          name="description" 
          content="Discover expert insights on finance, accounting, law, and compliance. Our blog offers valuable knowledge on taxes, regulations, investments, and business growth to guide your financial decisions." 
        />
        <meta 
          name="keywords" 
          content="Tax financial advisor, Financial accounting services, CPA services, Boston financial advisors" 
        />
        <meta name="author" content="Boston Financial" />
        <meta name="robots" content="follow, index" />
        <link rel="canonical" href="https://bostonfagroup.com" />
      </Helmet>


      
      <div
        style={{
          ...styles.cardContainer,
          display: window.innerWidth < 768 ? "none" : "",
          marginBottom: "50px",
          marginTop: "20px",
          alignItems: "center",
          justifyContent: "center",
          marginLeft: "70px",
          marginRight: "auto",
        }}
>

        <div className="pb-2 mb-5">
          <h3
            className="text-center"
            style={{
              color: "#072f5f",
              display: "inline",
              fontWeight: "bold",
              fontSize: "30px",
            }}
          >
            Insights from Boston Financial
          </h3>
        </div>

        <div style={styles.row}>
          {/* card1 */}
          <Link
            to="/international-tax-era"
            style={styles.card}
            onMouseEnter={() => setHovered(1)}
            onMouseLeave={() => setHovered(null)}
          >
            <div
              style={{
                ...styles.cardImage,
                backgroundImage: `url(${internaltax})`,
              }}
            >
              <div
                className={`cardOverlay ${
                  hovered === 1 ? "overlayVisible" : ""
                }`}
                style={{
                  ...styles.cardOverlay1,
                  ...(hovered === 1 ? styles.overlayVisible : {}),
                }}
              ></div>
              <div style={styles.cardDateContainer}>
                <div style={styles.cardDate}>Mar 15, 2025</div>
                <div style={styles.cardDateLine}></div>
              </div>
              <div style={styles.cardTitle}>
                Managing the New Global Minimum International <br /> Tax Era:
                Essential Facts for Multinationals
              </div>
            </div>
          </Link>
          {/* card1 */}

          {/* card2 */}
          <Link
            to="/fbar-penalties-revealed"
            style={styles.card}
            onMouseEnter={() => setHovered(2)}
            onMouseLeave={() => setHovered(null)}
          >
            <div
              style={{
                ...styles.cardImageSmall,
                backgroundImage: `url(${fbar2})`,
              }}
            >
              <div
                className={`cardOverlay ${
                  hovered === 2 ? "overlayVisible" : ""
                }`}
                style={{
                  ...styles.cardOverlay2,
                  ...(hovered === 2 ? styles.overlayVisible : {}),
                }}
              ></div>
              <div style={styles.cardDateContainer}>
                <div style={styles.cardDate}>Mar 10, 2025</div>
                <div style={styles.cardDateLine}></div>
              </div>
              <div style={styles.cardTitle2}>
                FBAR Penalties Revealed: What Every Foreign
                <br /> Investor Needs to Know
              </div>
            </div>
          </Link>
          {/* card2 */}
        </div>

        <div style={styles.row}>
          {/* card3 */}
          <Link
            to="/cryptocurrency-your-taxes"
            style={styles.card}
            onMouseEnter={() => setHovered(3)}
            onMouseLeave={() => setHovered(null)}
          >
            <div
              style={{
                ...styles.cardImageNoBorder,
                backgroundImage: `url(${internaltax1})`,
              }}
            >
              <div
                className={`cardOverlay ${
                  hovered === 3 ? "overlayVisible" : ""
                }`}
                style={{
                  ...styles.cardOverlay3,
                  ...(hovered === 3 ? styles.overlayVisible : {}),
                }}
              ></div>
              <div style={styles.cardDateContainer}>
                <div style={styles.cardDate}>Mar 5, 2025</div>
                <div style={styles.cardDateLine}></div>
              </div>
              <div style={styles.cardTitle}>
                Cryptocurrency and Your Taxes: A Guide for <br /> the Digital
                Age Investor
              </div>
            </div>
          </Link>
          {/* card3 */}

          {/* card4 */}
          <Link
            to="/Navigating-us-india-cross-border"
            style={styles.card}
            onMouseEnter={() => setHovered(4)}
            onMouseLeave={() => setHovered(null)}
          >
            <div
              style={{
                ...styles.cardImageNoBorder4,
                backgroundImage: `url(${taxing1})`,
              }}
            >
              <div
                className={`cardOverlay ${
                  hovered === 4 ? "overlayVisible" : ""
                }`}
                style={{
                  ...styles.cardOverlay4,
                  ...(hovered === 4 ? styles.overlayVisible : {}),
                }}
              ></div>
              <div style={styles.cardDateContainer}>
                <div style={styles.cardDate}>Feb 28, 2025</div>
                <div style={styles.cardDateLine}></div>
              </div>
              <div style={styles.cardTitle}>
                Navigating US-India Cross-Border Taxation:
                <br />
                Key Updates for 2025
              </div>
            </div>
          </Link>
          {/* card4 */}

          {/* card5 */}
          <Link
            to="/sustainability-reporting"
            style={styles.card}
            onMouseEnter={() => setHovered(5)}
            onMouseLeave={() => setHovered(null)}
          >
            <div
              style={{
                ...styles.cardImageNoBorder5,
                backgroundImage: `url(${sustain3})`,
              }}
            >
              <div
                className={`cardOverlay ${
                  hovered === 5 ? "overlayVisible" : ""
                }`}
                style={{
                  ...styles.cardOverlay5,
                  ...(hovered === 5 ? styles.overlayVisible : {}),
                }}
              ></div>
              <div style={styles.cardDateContainerOpposite}>
                <div style={styles.cardDateOpposite}>Feb 26, 2025</div>
                <div style={styles.cardDateLineOpposite}></div>
              </div>
              <div style={styles.cardTitle}>
                Sustainability Reporting: The Growing Importance
                <br /> for CPAs, CAs, and CSs
              </div>
            </div>
          </Link>
          {/* card5 */}
        </div>

        <div style={styles.row}>
          {/* {blog6} */}
          <Link
            to="/evolving-role-accounting-professional"
            style={styles.card}
            onMouseEnter={() => setHovered(6)}
            onMouseLeave={() => setHovered(null)}
          >
            <div
              style={{
                ...styles.cardImage6,
                backgroundImage: `url(${envole2})`,
                
              }}
            >
              <div
                className={`cardOverlay ${
                  hovered === 6 ? "overlayVisible" : ""
                }`}
                style={{
                  ...styles.cardOverlay6,
                  ...(hovered === 6 ? styles.overlayVisible : {}),
                }}
              ></div>
              <div style={styles.cardDateContainer}>
                <div style={styles.cardDate}>Feb 25, 2025</div>
                <div style={styles.cardDateLine}></div>
              </div>
              <div style={styles.cardTitle}>
                The Evolving Role of Accounting Professionals in <br /> ESG
                Practices: A 2025 Perspective
              </div>
            </div>
          </Link>
        </div>

        <div style={styles.row}>
          {/* card7 */}
          <Link
            to="/decoding-india-consumption-boom"
            style={styles.card}
            onMouseEnter={() => setHovered(7)}
            onMouseLeave={() => setHovered(null)}
          >
            <div
              style={{
                ...styles.cardImageNoBorder7,
                backgroundImage: `url(${newblogimg})`,
              }}
            >
              <div
                className={`cardOverlay ${
                  hovered === 7 ? "overlayVisible" : ""
                }`}
                style={{
                  ...styles.cardOverlay7,
                  ...(hovered === 7 ? styles.overlayVisible : {}),
                }}
              ></div>
              <div style={styles.cardDateContainer}>
                <div style={styles.cardDate}>Feb 21, 2025</div>
                <div style={styles.cardDateLine}></div>
              </div>
              <div style={styles.cardTitle}>
                Decoding India's Consumption Boom: BFAG's Investment Approaches
                <br />
                to the World's Fastest-Growing Major Economy
              </div>
            </div>
          </Link>
          {/* card7 */}

          {/* card8 */}
          <Link
            to="/virtual-cpa-services"
            style={styles.card}
            onMouseEnter={() => setHovered(8)}
            onMouseLeave={() => setHovered(null)}
          >
            <div
              style={{
                ...styles.cardImageNoBorder8,
                backgroundImage: `url(${virtual1})`,
              }}
            >
              <div
                className={`cardOverlay ${
                  hovered === 8 ? "overlayVisible" : ""
                }`}
                style={{
                  ...styles.cardOverlay8,
                  ...(hovered === 8 ? styles.overlayVisible : {}),
                }}
              ></div>
              <div style={styles.cardDateContainerOpposite}>
                <div style={styles.cardDateOpposite}>Jan 14, 2025</div>
                <div style={styles.cardDateLineOpposite}></div>
              </div>
              <div style={styles.cardTitle}>
                Virtual CPA Services: How to Maximize Your Business's
                <br /> Financial Health Remotely
              </div>
            </div>
          </Link>
          {/* card8 */}
        </div>

        {showMore && (
          <>
            <div style={styles.row}>
              {/* card 9 */}
              <Link
                to="/cryptocurreny-taxes-explained"
                style={styles.card}
                onMouseEnter={() => setHovered(9)}
                onMouseLeave={() => setHovered(null)}
              >
                <div
                  style={{
                    ...styles.cardImageNoBorder9,
                    backgroundImage: `url(${tax7})`,
                  }}
                >
                  <div
                    className={`cardOverlay ${
                      hovered === 9 ? "overlayVisible" : ""
                    }`}
                    style={{
                      ...styles.cardOverlay9,
                      ...(hovered === 9 ? styles.overlayVisible : {}),
                    }}
                  ></div>
                  <div style={styles.cardDateContainer}>
                    <div style={styles.cardDate}>Jan 5, 2025</div>
                    <div style={styles.cardDateLine}></div>
                  </div>
                  <div style={styles.cardTitle}>
                    Cryptocurrency Taxes Explained: <br /> A CPA’s Guide to
                    Navigating the Digital Currency Landscape
                  </div>
                </div>
              </Link>
              {/* card9 */}

              {/* card 10 */}
              <Link
                to="/from-seed-to-scale"
                style={styles.card}
                onMouseEnter={() => setHovered(10)}
                onMouseLeave={() => setHovered(null)}
              >
                <div
                  style={{
                    ...styles.cardImageNoBorder10,
                    backgroundImage: `url(${scale})`,
                  }}
                >
                  <div
                    className={`cardOverlay ${
                      hovered === 10 ? "overlayVisible" : ""
                    }`}
                    style={{
                      ...styles.cardOverlay10,
                      ...(hovered === 10 ? styles.overlayVisible : {}),
                    }}
                  ></div>
                  <div style={styles.cardDateContainer}>
                    <div style={styles.cardDate}>Jan 3, 2024</div>
                    <div style={styles.cardDateLine}></div>
                  </div>
                  <div style={styles.cardTitle}>
                    From Seed to Scale:
                    <br /> How Pune's CAs are Fuelling the City's Startup
                    Ecosystem
                  </div>
                </div>
              </Link>
              {/* card 10 */}
            </div>
            {/* latestnew blog */}

            <div style={styles.row}>
              {/* card 11 */}
              <Link
                to="/annual-inflation"
                style={styles.card}
                onMouseEnter={() => setHovered(11)}
                onMouseLeave={() => setHovered(null)}
              >
                <div
                  style={{
                    ...styles.cardImageNoBorder,
                    backgroundImage: `url(${blog10})`,
                  }}
                >
                  <div
                    className={`cardOverlay ${
                      hovered === 11 ? "overlayVisible" : ""
                    }`}
                    style={{
                      ...styles.cardOverlay10,
                      ...(hovered === 11 ? styles.overlayVisible : {}),
                    }}
                  ></div>
                  <div style={styles.cardDateContainer}>
                    <div style={styles.cardDate}>Dec 20, 2024</div>
                    <div style={styles.cardDateLine}></div>
                  </div>
                  <div style={styles.cardTitle}>
                    Annual Inflation Adjustments for Tax Year 2025: What Boston{" "}
                    <br /> Taxpayers Should Know
                  </div>
                </div>
              </Link>
              {/* card 11 */}

              {/* card 12 */}
              <Link
                to="/audited-financial-report"
                style={styles.card}
                onMouseEnter={() => setHovered(12)}
                onMouseLeave={() => setHovered(null)}
              >
                <div
                  style={{
                    ...styles.cardImageNoBorder4,
                    backgroundImage: `url(${blog7})`,
                  }}
                >
                  <div
                    className={`cardOverlay ${
                      hovered === 12 ? "overlayVisible" : ""
                    }`}
                    style={{
                      ...styles.cardOverlay4,
                      ...(hovered === 12 ? styles.overlayVisible : {}),
                    }}
                  ></div>
                  <div style={styles.cardDateContainer}>
                    <div style={styles.cardDate}>Dec 15, 2024</div>
                    <div style={styles.cardDateLine}></div>
                  </div>
                  <div style={styles.cardTitle}>
                    Meaning of Audited Financial Reports to Businesses
                  </div>
                </div>
              </Link>
              {/* card 12 */}

              {/* card 13 */}
              <Link
                to="/increasing-incidence-of-financial"
                style={styles.card}
                onMouseEnter={() => setHovered(13)}
                onMouseLeave={() => setHovered(null)}
              >
                <div
                  style={{
                    ...styles.cardImageNoBorder5,
                    backgroundImage: `url(${blogee3})`,
                  }}
                >
                  <div
                    className={`cardOverlay ${
                      hovered === 13 ? "overlayVisible" : ""
                    }`}
                    style={{
                      ...styles.cardOverlay5,
                      ...(hovered === 13 ? styles.overlayVisible : {}),
                    }}
                  ></div>
                  <div style={styles.cardDateContainerOpposite}>
                    <div style={styles.cardDateOpposite}>Dec 11, 2024</div>
                    <div style={styles.cardDateLineOpposite}></div>
                  </div>
                  <div style={styles.cardTitle}>
                    The Increasing Incidence of Financial Statement Retractions:{" "}
                    <br /> Implications and Solutions for United States
                    Companies
                  </div>
                </div>
              </Link>
              {/* card 13 */}
            </div>

            {/* card 14 */}
            <div style={styles.row}>
              {/* {blog6} */}
              <Link
                to="/Supreme-court-defers-fundamental"
                style={styles.card}
                onMouseEnter={() => setHovered(14)}
                onMouseLeave={() => setHovered(null)}
              >
                <div
                  style={{
                    ...styles.cardImage14,
                    backgroundImage: `url(${blogee2})`,
                  }}
                >
                  <div
                    className={`cardOverlay ${
                      hovered === 14 ? "overlayVisible" : ""
                    }`}
                    style={{
                      ...styles.cardOverlay14,
                      ...(hovered === 14 ? styles.overlayVisible : {}),
                    }}
                  ></div>
                  <div style={styles.cardDateContainer}>
                    <div style={styles.cardDate}>Dec 7, 2024</div>
                    <div style={styles.cardDateLine}></div>
                  </div>
                  <div style={styles.cardTitle}>
                    Supreme Court Defers Fundamental Constitutional Question in
                    Upholding Tax on Foreign Business Income
                  </div>
                </div>
              </Link>
            </div>
            {/* card 14 */}

            <div style={styles.row}>
              {/* card4 */}
              <Link
                to="/IRS-again-delays-effective-date"
                style={styles.card}
                onMouseEnter={() => setHovered(15)}
                onMouseLeave={() => setHovered(null)}
              >
                <div
                  style={{
                    ...styles.cardImageNoBorder4,
                    backgroundImage: `url(${blogoverlay})`,
                  }}
                >
                  <div
                    className={`cardOverlay ${
                      hovered === 15 ? "overlayVisible" : ""
                    }`}
                    style={{
                      ...styles.cardOverlay4,
                      ...(hovered === 15 ? styles.overlayVisible : {}),
                    }}
                  ></div>
                  <div style={styles.cardDateContainer}>
                    <div style={styles.cardDate}>Dec 4, 2024</div>
                    <div style={styles.cardDateLine}></div>
                  </div>
                  <div style={styles.cardTitle}>
                    IRS again delays effective date of $600 threshold for <br />
                    Form 1099-K reporting
                  </div>
                </div>
              </Link>
              {/* card4 */}

              {/* card5 */}
              <Link
                to="/navigating-crypto"
                style={styles.card}
                onMouseEnter={() => setHovered(16)}
                onMouseLeave={() => setHovered(null)}
              >
                <div
                  style={{
                    ...styles.cardImageNoBorder5,
                    backgroundImage: `url(${blogoverlay1})`,
                  }}
                >
                  <div
                    className={`cardOverlay ${
                      hovered === 16 ? "overlayVisible" : ""
                    }`}
                    style={{
                      ...styles.cardOverlay5,
                      ...(hovered === 16 ? styles.overlayVisible : {}),
                    }}
                  ></div>
                  <div style={styles.cardDateContainerOpposite}>
                    <div style={styles.cardDateOpposite}>August 11, 2024</div>
                    <div style={styles.cardDateLineOpposite}></div>
                  </div>
                  <div style={styles.cardTitle}>
                    Navigating the Crypto Landscape: What Investors Should Know
                    in 2024
                  </div>
                </div>
              </Link>
              {/* card5 */}

              <Link
                to="/financial-services"
                style={styles.card}
                onMouseEnter={() => setHovered(17)}
                onMouseLeave={() => setHovered(null)}
              >
                <div
                  style={{
                    ...styles.cardImageNoBorder,
                    backgroundImage: `url(${blogoverlay2})`,
                  }}
                >
                  <div
                    className={`cardOverlay ${
                      hovered === 17 ? "overlayVisible" : ""
                    }`}
                    style={{
                      ...styles.cardOverlay3,
                      ...(hovered === 17 ? styles.overlayVisible : {}),
                    }}
                  ></div>
                  <div style={styles.cardDateContainer}>
                    <div style={styles.cardDate}>August 7, 2024</div>
                    <div style={styles.cardDateLine}></div>
                  </div>
                  <div style={styles.cardTitle}>
                    The Rise of AI in Financial Services: What Investors Need to
                    Know
                  </div>
                </div>
              </Link>
              {/* card3 */}
            </div>

            <div style={styles.row}>
              {/* card7 */}
              <Link
                to="/sustainable-finance"
                style={styles.card}
                onMouseEnter={() => setHovered(18)}
                onMouseLeave={() => setHovered(null)}
              >
                <div
                  style={{
                    ...styles.cardImageNoBorder7,
                    backgroundImage: `url(${latestblog1})`,
                  }}
                >
                  <div
                    className={`cardOverlay ${
                      hovered === 18 ? "overlayVisible" : ""
                    }`}
                    style={{
                      ...styles.cardOverlay7,
                      ...(hovered === 18 ? styles.overlayVisible : {}),
                    }}
                  ></div>
                  <div style={styles.cardDateContainer}>
                    <div style={styles.cardDate}>August 2, 2024</div>
                    <div style={styles.cardDateLine}></div>
                  </div>
                  <div style={styles.cardTitle}>
                    Sustainable Finance: Boston Financial’s ESG Approach
                  </div>
                </div>
              </Link>
              {/* card7 */}

              {/* card8 */}
              <Link
                to="/navigating-tax-changes"
                style={styles.card}
                onMouseEnter={() => setHovered(19)}
                onMouseLeave={() => setHovered(null)}
              >
                <div
                  style={{
                    ...styles.cardImageNoBorder8,
                    backgroundImage: `url(${blogoverlay4})`,
                  }}
                >
                  <div
                    className={`cardOverlay ${
                      hovered === 19 ? "overlayVisible" : ""
                    }`}
                    style={{
                      ...styles.cardOverlay8,
                      ...(hovered === 19 ? styles.overlayVisible : {}),
                    }}
                  ></div>
                  <div style={styles.cardDateContainerOpposite}>
                    <div style={styles.cardDateOpposite}>July 31, 2024</div>
                    <div style={styles.cardDateLineOpposite}></div>
                  </div>
                  <div style={styles.cardTitle}>
                    Navigating Tax Changes in 2024: What You Need to Know{" "}
                  </div>
                </div>
              </Link>
              {/* card8 */}
            </div>

            <div style={styles.row}>
              {/* {blog6} */}
              <Link
                to="/the-future-of-accouting"
                style={styles.card}
                onMouseEnter={() => setHovered(20)}
                onMouseLeave={() => setHovered(null)}
              >
                <div
                  style={{
                    ...styles.cardImage14,
                    backgroundImage: `url(${blog11})`,
                  }}
                >
                  <div
                    className={`cardOverlay ${
                      hovered === 20 ? "overlayVisible" : ""
                    }`}
                    style={{
                      ...styles.cardOverlay14,
                      ...(hovered === 20 ? styles.overlayVisible : {}),
                    }}
                  ></div>
                  <div style={styles.cardDateContainer}>
                    <div style={styles.cardDate}>July 30, 2024</div>
                    <div style={styles.cardDateLine}></div>
                  </div>
                  <div style={styles.cardTitle}>
                    The Future of Accounting Technology: Navigating Digital
                    <br /> Transformation
                  </div>
                </div>
              </Link>
            </div>
            
          </>
        )}
        <Button
          className="buttonUnique"
          variant="contained"
          color="primary"
          style={{ backgroundColor: "#072f5f", marginTop: "20px" }}
          onClick={toggleShowMore}
        >
          {showMore ? "Show Less" : "Read More"}
        </Button>
      </div>

      <div style={{ display: window.innerWidth > 768 ? "none" : "" }}>
  <BlogMobile />
</div>

     
    </>
  );
};


const styles = {
  cardContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "@media (max-width: 768px)": {
      display: "none",
    },
  }
  ,
  row: {
    display: "flex",
    gap: "20px",
    marginBottom: "20px",
  },
  card: {
    position: "relative",
    overflow: "hidden",
    cursor: "pointer",
  },
  cardImage: {
    width: "780px",
    height: "410px",
    backgroundSize: "cover",
    backgroundPosition: "center",
    borderBottomRightRadius: "39%",
    clipPath: "polygon(0 0, 100% 0, 100% 85%, 85% 100%, 0 100%)",
    position: "relative",
    transition: "transform 0.3s ease",
    marginRight: "0px",
  },
  cardImage6: {
    width: "1200px",
    height: "400px",
    backgroundSize: "cover",
    backgroundPosition: "center",
    // borderBottomRightRadius: '39%',
    // clipPath: "polygon(0 0, 100% 0, 100% 85%, 85% 100%, 0 100%)",
    position: "relative",
    transition: "transform 0.3s ease",
    marginRight: "0px",
  },
  cardImage14: {
    width: "1200px",
    height: "400px",
    backgroundSize: "cover",
    backgroundPosition: "center",
    // borderBottomRightRadius: '39%',
    // clipPath: "polygon(0 0, 100% 0, 100% 85%, 85% 100%, 0 100%)",
    position: "relative",
    transition: "transform 0.3s ease",
    marginRight: "0px",
  },
  cardImageSmall: {
    width: "400px",
    height: "410px",
    backgroundSize: "cover",
    backgroundPosition: "center",
    borderBottomLeftRadius: "40%",
    position: "relative",
    transition: "transform 0.3s ease",
    marginLeft: "0px",
  },
  cardImageNoBorder: {
    width: "400px",
    height: "410px",
    backgroundSize: "cover",
    backgroundPosition: "center",
    position: "relative",
  },
  cardImageNoBorder10: {
    width: "590px",
    height: "410px",
    backgroundSize: "cover",
    backgroundPosition: "center",
    position: "relative",
  },
  cardImageNoBorder9: {
    width: "590px",
    height: "410px",
    backgroundSize: "cover",
    backgroundPosition: "center",
    position: "relative",
  },
  cardImageNoBorder4: {
    width: "380px",
    height: "410px",
    backgroundSize: "cover",
    backgroundPosition: "center",
    position: "relative",
    borderTopRightRadius: "45%",
    transition: "transform 0.3s ease",
  },

  cardImageNoBorder7: {
    width: "590px",
    height: "410px",
    backgroundSize: "cover",
    backgroundPosition: "center",
    position: "relative",
    borderTopRightRadius: "45%",
    transition: "transform 0.3s ease",
  },
  cardImageNoBorder5: {
    width: "380px",
    height: "410px",
    backgroundSize: "cover",
    backgroundPosition: "center",
    position: "relative",
    borderTopLeftRadius: "45%",
    transition: "transform 0.3s ease",
  },
  cardImageNoBorder8: {
    width: "590px",
    height: "410px",
    backgroundSize: "cover",
    backgroundPosition: "center",
    position: "relative",
    borderTopLeftRadius: "45%",
    transition: "transform 0.3s ease",
  },
  cardOverlay1: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    borderBottomRightRadius: "39%",
    backgroundColor: "transparent",
    transition: "background-color 0.3s ease",
  },
  cardOverlay6: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    // borderBottomRightRadius: '39%',
    backgroundColor: "transparent",
    transition: "background-color 0.3s ease",
  },
  cardOverlay14: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    // borderBottomRightRadius: '39%',
    backgroundColor: "transparent",
    transition: "background-color 0.3s ease",
  },
  cardOverlay2: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    borderBottomLeftRadius: "40%",
    backgroundColor: "transparent",
    transition: "background-color 0.3s ease",
  },
  cardOverlay3: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    // borderBottomRightRadius: '38%',
    backgroundColor: "transparent",
    transition: "background-color 0.3s ease",
  },
  cardOverlay10: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    // borderBottomRightRadius: '38%',
    backgroundColor: "transparent",
    transition: "background-color 0.3s ease",
  },
  cardOverlay9: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    // borderBottomRightRadius: '38%',
    backgroundColor: "transparent",
    transition: "background-color 0.3s ease",
  },
  cardOverlay4: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    borderTopRightRadius: "45%",
    backgroundColor: "transparent",
    transition: "background-color 0.3s ease",
  },
  cardOverlay7: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    borderTopRightRadius: "45%",
    backgroundColor: "transparent",
    transition: "background-color 0.3s ease",
  },
  cardOverlay5: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    borderTopLeftRadius: "45%",
    backgroundColor: "transparent",
    transition: "background-color 0.3s ease",
  },
  cardOverlay8: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    borderTopLeftRadius: "45%",
    backgroundColor: "transparent",
    transition: "background-color 0.3s ease",
  },
  overlayVisible: {
    backgroundColor: "rgba(0, 112, 255, 0.61)",
  },
  cardDateContainer: {
    position: "absolute",
    top: "10px",
    left: "10px",
    display: "flex",
    alignItems: "center",
  },
  cardDate: {
    color: "white",
    background: "rgba(0, 0, 0, 0.5)",
    padding: "5px 10px",
    borderRadius: "5px",
  },
  cardDateLine: {
    flexGrow: 1,
    height: "2px",
    background: "white",
    marginLeft: "10px",
  },
  cardTitle: {
    position: "absolute",
    bottom: "10px",
    left: "10px",
    right: "10px",
    color: "white",
    fontSize: "22px",
    fontWeight: "600",
    textShadow: "1px 1px 2px rgba(0, 0, 0, 0.5)",
  },
  cardTitle2: {
    position: "absolute",
    bottom: "40px",
    left: "50px",
    right: "10px",
    color: "white",
    fontSize: "22px",
    fontWeight: "600",
    textShadow: "1px 1px 2px rgba(0, 0, 0, 0.5)",
  },
  cardDateContainerOpposite: {
    position: "absolute",
    top: "10px",
    right: "10px",
    display: "flex",
    alignItems: "center",
  },
  cardDateOpposite: {
    color: "white",
    background: "rgba(0, 0, 0, 0.5)",
    padding: "5px 10px",
    borderRadius: "5px",
  },
  cardDateLineOpposite: {
    flexGrow: 1,
    height: "2px",
    background: "white",
    marginRight: "10px",
  },
};

export default BlogTwo;
