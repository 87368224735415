import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link } from "react-router-dom";
import virtual1 from "../../assets/images/blogs/virtual1.png";
import "../BlogOne.css";
import "./RecentBlogs.css";
import "./RecentBlogsMobile.css";
import newblogimg from "../../assets/images/blogs/newblogimg2.png";
import { Button } from "@mui/material";
import sustain3 from '../../assets/images/blogs/Sustainability3.png'
import fbar2 from '../../assets/images/blogs/FBAR3.png'
import internaltax from '../../assets/images/blogs/InternationalTaxEra1.png'
import internaltax1 from '../../assets/images/blogs/InternationalTaxEra2.png'
import envole2 from "../../assets/images/blogs/bostonblog.png";
import envole from '../../assets/images/blogs/envole.png'
import taxing1 from "../../assets/images/blogs/taxing1.png";
// import Subblogs from "./subblogs";
// import SubBlogs from "./subblogs";


const RecentBlogs = () => {
  const [hovered, setHovered] = useState(null);

  return (
    <div
    style={{
      ...styles.cardContainer,
      display: window.innerWidth < 768 ? "none" : "",
      marginBottom: "50px",
      marginTop: "20px",
      alignItems: "center",
      justifyContent: "center",
      marginLeft: "70px",
      marginRight: "auto",
    }}
>
      <div className="pb-2 mb-5">
        <h3 className="text-center blog-title" style={styles.title}>
          Insights from Boston Financialg
        </h3>
      </div>

       <div style={styles.row}>
              {/* card1 */}
              <Link
                to="/international-tax-era"
                style={styles.card}
                onMouseEnter={() => setHovered(1)}
                onMouseLeave={() => setHovered(null)}
              >
                <div
                  style={{
                    ...styles.cardImage,
                    backgroundImage: `url(${internaltax})`,
                  }}
                >
                  <div
                    className={`cardOverlay ${
                      hovered === 1 ? "overlayVisible" : ""
                    }`}
                    style={{
                      ...styles.cardOverlay1,
                      ...(hovered === 1 ? styles.overlayVisible : {}),
                    }}
                  ></div>
                  <div style={styles.cardDateContainer}>
                    <div style={styles.cardDate}>Mar 15, 2025</div>
                    <div style={styles.cardDateLine}></div>
                  </div>
                  <div style={styles.cardTitle}>
                    Managing the New Global Minimum International <br /> Tax Era:
                    Essential Facts for Multinationals
                  </div>
                </div>
              </Link>
              {/* card1 */}
    
              {/* card2 */}
              <Link
                to="/fbar-penalties-revealed"
                style={styles.card}
                onMouseEnter={() => setHovered(2)}
                onMouseLeave={() => setHovered(null)}
              >
                <div
                  style={{
                    ...styles.cardImageSmall,
                    backgroundImage: `url(${fbar2})`,
                  }}
                >
                  <div
                    className={`cardOverlay ${
                      hovered === 2 ? "overlayVisible" : ""
                    }`}
                    style={{
                      ...styles.cardOverlay2,
                      ...(hovered === 2 ? styles.overlayVisible : {}),
                    }}
                  ></div>
                  <div style={styles.cardDateContainer}>
                    <div style={styles.cardDate}>Mar 10, 2025</div>
                    <div style={styles.cardDateLine}></div>
                  </div>
                  <div style={styles.cardTitle2}>
                    FBAR Penalties Revealed: What Every Foreign
                    <br /> Investor Needs to Know
                  </div>
                </div>
              </Link>
              {/* card2 */}
            </div>
    
            <div style={styles.row}>
              {/* card3 */}
              <Link
                to="/cryptocurrency-your-taxes"
                style={styles.card}
                onMouseEnter={() => setHovered(3)}
                onMouseLeave={() => setHovered(null)}
              >
                <div
                  style={{
                    ...styles.cardImageNoBorder,
                    backgroundImage: `url(${internaltax1})`,
                  }}
                >
                  <div
                    className={`cardOverlay ${
                      hovered === 3 ? "overlayVisible" : ""
                    }`}
                    style={{
                      ...styles.cardOverlay3,
                      ...(hovered === 3 ? styles.overlayVisible : {}),
                    }}
                  ></div>
                  <div style={styles.cardDateContainer}>
                    <div style={styles.cardDate}>Mar 5, 2025</div>
                    <div style={styles.cardDateLine}></div>
                  </div>
                  <div style={styles.cardTitle}>
                    Cryptocurrency and Your Taxes: A Guide for <br /> the Digital
                    Age Investor
                  </div>
                </div>
              </Link>
              {/* card3 */}
    
              {/* card4 */}
              <Link
                to="/Navigating-us-india-cross-border"
                style={styles.card}
                onMouseEnter={() => setHovered(4)}
                onMouseLeave={() => setHovered(null)}
              >
                <div
                  style={{
                    ...styles.cardImageNoBorder4,
                    backgroundImage: `url(${taxing1})`,
                  }}
                >
                  <div
                    className={`cardOverlay ${
                      hovered === 4 ? "overlayVisible" : ""
                    }`}
                    style={{
                      ...styles.cardOverlay4,
                      ...(hovered === 4 ? styles.overlayVisible : {}),
                    }}
                  ></div>
                  <div style={styles.cardDateContainer}>
                    <div style={styles.cardDate}>Feb 28, 2025</div>
                    <div style={styles.cardDateLine}></div>
                  </div>
                  <div style={styles.cardTitle}>
                    Navigating US-India Cross-Border Taxation:
                    <br />
                    Key Updates for 2025
                  </div>
                </div>
              </Link>
              {/* card4 */}
    
              {/* card5 */}
              <Link
                to="/sustainability-reporting"
                style={styles.card}
                onMouseEnter={() => setHovered(5)}
                onMouseLeave={() => setHovered(null)}
              >
                <div
                  style={{
                    ...styles.cardImageNoBorder5,
                    backgroundImage: `url(${sustain3})`,
                  }}
                >
                  <div
                    className={`cardOverlay ${
                      hovered === 5 ? "overlayVisible" : ""
                    }`}
                    style={{
                      ...styles.cardOverlay5,
                      ...(hovered === 5 ? styles.overlayVisible : {}),
                    }}
                  ></div>
                  <div style={styles.cardDateContainerOpposite}>
                    <div style={styles.cardDateOpposite}>Feb 26, 2025</div>
                    <div style={styles.cardDateLineOpposite}></div>
                  </div>
                  <div style={styles.cardTitle}>
                    Sustainability Reporting: The Growing Importance
                    <br /> for CPAs, CAs, and CSs
                  </div>
                </div>
              </Link>
              {/* card5 */}
            </div>

      <div style={{ textAlign: "center", marginTop: "20px" }}>
        <Link to="/blogs">
          <Button
            className="buttonUnique read-more-button"
            variant="contained"
            color="primary"
            style={{ 
              backgroundColor: "#072f5f",
              padding: "8px 24px",
              fontSize: "16px",
              minWidth: "120px"
            }}
          >
            Read More
          </Button>
        </Link>
      </div>
      {/* <div style={{ display: window.innerWidth < 768 ? "none" : "" }}>
      <SubBlogs />
      </div> */}
      
    </div>
  );
};

const styles = {
  cardContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "@media (max-width: 768px)": {
      display: "none",
    },
  }
  ,
  row: {
    display: "flex",
    gap: "20px",
    marginBottom: "20px",
  },
  card: {
    position: "relative",
    overflow: "hidden",
    cursor: "pointer",
  },
  cardImage: {
    width: "780px",
    height: "410px",
    backgroundSize: "cover",
    backgroundPosition: "center",
    borderBottomRightRadius: "39%",
    clipPath: "polygon(0 0, 100% 0, 100% 85%, 85% 100%, 0 100%)",
    position: "relative",
    transition: "transform 0.3s ease",
    marginRight: "0px",
  },
  cardImage6: {
    width: "1200px",
    height: "400px",
    backgroundSize: "cover",
    backgroundPosition: "center",
    // borderBottomRightRadius: '39%',
    // clipPath: "polygon(0 0, 100% 0, 100% 85%, 85% 100%, 0 100%)",
    position: "relative",
    transition: "transform 0.3s ease",
    marginRight: "0px",
  },
  cardImage14: {
    width: "1200px",
    height: "400px",
    backgroundSize: "cover",
    backgroundPosition: "center",
    // borderBottomRightRadius: '39%',
    // clipPath: "polygon(0 0, 100% 0, 100% 85%, 85% 100%, 0 100%)",
    position: "relative",
    transition: "transform 0.3s ease",
    marginRight: "0px",
  },
  cardImageSmall: {
    width: "400px",
    height: "410px",
    backgroundSize: "cover",
    backgroundPosition: "center",
    borderBottomLeftRadius: "40%",
    position: "relative",
    transition: "transform 0.3s ease",
    marginLeft: "0px",
  },
  cardImageNoBorder: {
    width: "400px",
    height: "410px",
    backgroundSize: "cover",
    backgroundPosition: "center",
    position: "relative",
  },
  cardImageNoBorder10: {
    width: "590px",
    height: "410px",
    backgroundSize: "cover",
    backgroundPosition: "center",
    position: "relative",
  },
  cardImageNoBorder9: {
    width: "590px",
    height: "410px",
    backgroundSize: "cover",
    backgroundPosition: "center",
    position: "relative",
  },
  cardImageNoBorder4: {
    width: "380px",
    height: "410px",
    backgroundSize: "cover",
    backgroundPosition: "center",
    position: "relative",
    borderTopRightRadius: "45%",
    transition: "transform 0.3s ease",
  },

  cardImageNoBorder7: {
    width: "590px",
    height: "410px",
    backgroundSize: "cover",
    backgroundPosition: "center",
    position: "relative",
    borderTopRightRadius: "45%",
    transition: "transform 0.3s ease",
  },
  cardImageNoBorder5: {
    width: "380px",
    height: "410px",
    backgroundSize: "cover",
    backgroundPosition: "center",
    position: "relative",
    borderTopLeftRadius: "45%",
    transition: "transform 0.3s ease",
  },
  cardImageNoBorder8: {
    width: "590px",
    height: "410px",
    backgroundSize: "cover",
    backgroundPosition: "center",
    position: "relative",
    borderTopLeftRadius: "45%",
    transition: "transform 0.3s ease",
  },
  cardOverlay1: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    borderBottomRightRadius: "39%",
    backgroundColor: "transparent",
    transition: "background-color 0.3s ease",
  },
  cardOverlay6: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    // borderBottomRightRadius: '39%',
    backgroundColor: "transparent",
    transition: "background-color 0.3s ease",
  },
  cardOverlay14: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    // borderBottomRightRadius: '39%',
    backgroundColor: "transparent",
    transition: "background-color 0.3s ease",
  },
  cardOverlay2: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    borderBottomLeftRadius: "40%",
    backgroundColor: "transparent",
    transition: "background-color 0.3s ease",
  },
  cardOverlay3: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    // borderBottomRightRadius: '38%',
    backgroundColor: "transparent",
    transition: "background-color 0.3s ease",
  },
  cardOverlay10: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    // borderBottomRightRadius: '38%',
    backgroundColor: "transparent",
    transition: "background-color 0.3s ease",
  },
  cardOverlay9: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    // borderBottomRightRadius: '38%',
    backgroundColor: "transparent",
    transition: "background-color 0.3s ease",
  },
  cardOverlay4: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    borderTopRightRadius: "45%",
    backgroundColor: "transparent",
    transition: "background-color 0.3s ease",
  },
  cardOverlay7: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    borderTopRightRadius: "45%",
    backgroundColor: "transparent",
    transition: "background-color 0.3s ease",
  },
  cardOverlay5: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    borderTopLeftRadius: "45%",
    backgroundColor: "transparent",
    transition: "background-color 0.3s ease",
  },
  cardOverlay8: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    borderTopLeftRadius: "45%",
    backgroundColor: "transparent",
    transition: "background-color 0.3s ease",
  },
  overlayVisible: {
    backgroundColor: "rgba(0, 112, 255, 0.61)",
  },
  cardDateContainer: {
    position: "absolute",
    top: "10px",
    left: "10px",
    display: "flex",
    alignItems: "center",
  },
  cardDate: {
    color: "white",
    background: "rgba(0, 0, 0, 0.5)",
    padding: "5px 10px",
    borderRadius: "5px",
  },
  cardDateLine: {
    flexGrow: 1,
    height: "2px",
    background: "white",
    marginLeft: "10px",
  },
  cardTitle: {
    position: "absolute",
    bottom: "10px",
    left: "10px",
    right: "10px",
    color: "white",
    fontSize: "22px",
    fontWeight: "600",
    textShadow: "1px 1px 2px rgba(0, 0, 0, 0.5)",
  },
  cardTitle2: {
    position: "absolute",
    bottom: "40px",
    left: "50px",
    right: "10px",
    color: "white",
    fontSize: "22px",
    fontWeight: "600",
    textShadow: "1px 1px 2px rgba(0, 0, 0, 0.5)",
  },
  cardDateContainerOpposite: {
    position: "absolute",
    top: "10px",
    right: "10px",
    display: "flex",
    alignItems: "center",
  },
  cardDateOpposite: {
    color: "white",
    background: "rgba(0, 0, 0, 0.5)",
    padding: "5px 10px",
    borderRadius: "5px",
  },
  cardDateLineOpposite: {
    flexGrow: 1,
    height: "2px",
    background: "white",
    marginRight: "10px",
  },
};

export default RecentBlogs;
