import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";

// Import all the images (assuming you're using the same images from the original component)
import internaltax from "../assets/images/blogs/InternationalTaxEra1.png";
import fbar2 from "../assets/images/blogs/FBAR2.png";
import internaltax1 from "../assets/images/blogs/InternationalTaxEra2.png";
import taxing1 from "../assets/images/blogs/taxing1.png";
import sustain3 from "../assets/images/blogs/Sustainability3.png";
import envole2 from "../assets/images/blogs/bostonblog.png";
import newblogimg from "../assets/images/blogs/newblogimg2.png";
import virtual1 from "../assets/images/blogs/virtual1.png";
// ... import other images as needed

const BlogMobile = () => {
  const [showMore, setShowMore] = useState(false);

  const MobileBlogCard = ({ 
    to, 
    image, 
    date, 
    title 
  }) => {
    return (
      <Link 
        to={to} 
        className="mobile-blog-card flex flex-col bg-white shadow-md rounded-lg overflow-hidden mb-4"
      >
        <div 
          className="blog-card-image h-48 bg-cover bg-center"
          style={{ backgroundImage: `url(${image})` }}
        />
        <div className="p-4">
          <div className="text-sm text-gray-500 mb-2">{date}</div>
          <h3 className="text-lg font-semibold text-gray-800">{title}</h3>
        </div>
      </Link>
    );
  };

  const mobileBlogPosts = [
    {
      to: "/international-tax-era",
      image: internaltax,
      date: "Mar 15, 2025",
      title: "Managing the New Global Minimum International Tax Era: Essential Facts for Multinationals"
    },
    {
      to: "/fbar-penalties-revealed",
      image: fbar2,
      date: "Mar 10, 2025",
      title: "FBAR Penalties Revealed: What Every Foreign Investor Needs to Know"
    },
    {
      to: "/cryptocurrency-your-taxes",
      image: internaltax1,
      date: "Mar 5, 2025",
      title: "Cryptocurrency and Your Taxes: A Guide for the Digital Age Investor"
    },
    {
      to: "/Navigating-us-india-cross-border",
      image: taxing1,
      date: "Feb 28, 2025",
      title: "Navigating US-India Cross-Border Taxation: Key Updates for 2025"
    },
    {
      to: "/sustainability-reporting",
      image: sustain3,
      date: "Feb 26, 2025",
      title: "Sustainability Reporting: The Growing Importance for CPAs, CAs, and CSs"
    },
    {
      to: "/evolving-role-accounting-professional",
      image: envole2,
      date: "Feb 25, 2025",
      title: "The Evolving Role of Accounting Professionals in ESG Practices: A 2025 Perspective"
    },
    {
      to: "/decoding-india-consumption-boom",
      image: newblogimg,
      date: "Feb 21, 2025",
      title: "Decoding India's Consumption Boom: BFAG's Investment Approaches to the World's Fastest-Growing Major Economy"
    },
    {
      to: "/virtual-cpa-services",
      image: virtual1,
      date: "Jan 14, 2025",
      title: "Virtual CPA Services: How to Maximize Your Business's Financial Health Remotely"
    }
  ];

  const additionalMobileBlogPosts = [
    // Add more blog posts from the original component
    // Follow the same structure as mobileBlogPosts
  ];

  return (
    <div className="block md:hidden p-4">
      <div className="pb-4 mb-4 text-center">
        <h3 className="text-2xl font-bold" style={{ color: "#072f5f" , marginTop: "90px"}}>
          Insights from Boston Financial
        </h3>
      </div>

      <div className="space-y-4">
        {mobileBlogPosts.map((post, index) => (
          <MobileBlogCard 
            key={index}
            to={post.to}
            image={post.image}
            date={post.date}
            title={post.title}
          />
        ))}

        {showMore && additionalMobileBlogPosts.map((post, index) => (
          <MobileBlogCard 
            key={index}
            to={post.to}
            image={post.image}
            date={post.date}
            title={post.title}
          />
        ))}
      </div>

      {additionalMobileBlogPosts.length > 0 && (
        <div className="text-center mt-4">
          <Button
            variant="contained"
            color="primary"
            style={{ backgroundColor: "#072f5f" }}
            onClick={() => setShowMore(!showMore)}
          >
            {showMore ? "Show Less" : "Read More"}
          </Button>
        </div>
      )}
    </div>
  );
};

export default BlogMobile;